import React from "react"

export const CafePageIntro = () => {
  return (
    <section className="cafePage__introContents">
      <dl className="cafePage__contentsConcept">
        <dd>
          <div className="cafePage__descArea">
            <h2 className="cafePage__descTitle pc">
              洗濯をするときも、しないときも
              <br />
              Cafeでほっとひと息、心地よい時間
            </h2>
            <h2 className="cafePage__descTitle sp">
              洗濯をするときも、しないときもBaluko Cafeでほっとひと息、
              <br />
              心地よい時間
            </h2>
            <p className="cafePage__descLead fw-b pc">
              ランドリーで衣類をきれいにするようにカフェでも心地よいひと時をお過ごしいただけますように。
              <br />
              日々の中で、ささやかでも、幸せな気持ちになれるこだわりのメニューをご用意してお待ちしています。
              <br />
              店内だけでなく、テイクアウトでもお楽しみいただけます。
            </p>
            <p className="cafePage__descLead sp fw-b">
              ランドリーで衣類をきれいにするようにカフェでも心地よいひと時をお過ごしいただけますように。日々の中で、ささやかでも、幸せな気持ちになれるこだわりのメニューをご用意してお待ちしています。店内だけでなく、テイクアウトでもお楽しみいただけます。
            </p>
          </div>
        </dd>
        <div className="cafePage__imageArea">
          <dt className="cafePage__imageItemPc pc">
            <img
              src="https://balukoweb.blob.core.windows.net/images/cafe/logo/baluko-cafe-logo.png"
              className="cafePage__cafeIcon"
              alt="バルコカフェ"
              loading="lazy"
            />
          </dt>
          <dt className="cafePage__imageItemPc pc">
            <img
              src="https://balukoweb.blob.core.windows.net/images/cafe/logo/donuts-biyori-logo.png"
              alt="バルコカフェ"
              className="cafePage__donutIcon"
              loading="lazy"
            />
          </dt>
          <dt className="cafePage__imageItemSp sp">
            <img
              src="https://balukoweb.blob.core.windows.net/images/cafe/logo/baluko-cafe-logo.png"
              className="cafePage__cafeIcon"
              alt="バルコカフェ"
              loading="lazy"
            />
          </dt>
          <dt className="cafePage__imageItemSp sp">
            <img
              src="https://balukoweb.blob.core.windows.net/images/cafe/logo/donuts-biyori-logo.png"
              alt="バルコカフェ"
              className="cafePage__donutIcon"
              loading="lazy"
            />
          </dt>
        </div>
      </dl>
    </section>
  )
}
