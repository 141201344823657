import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import { Fv } from "components/fv"
import { CafePageIntro } from "components/cafe/cafePageIntro"
import { CafePageNews } from "components/cafe/cafePageNews"
import { CafePageMenu } from "components/cafe/cafePageMenu"
import { CafePageStore } from "components/cafe/cafePageStore"

const CafePage = ({ data }) => {
  return (
    <Layout>
      <div className="cafePage">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/cafe/mv/mv-img.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/cafe/mv/sp-mv-img.jpg"
          fvTitle="Café"
          color="white"
        />

        <CafePageIntro />      
        <CafePageNews data={data} />
        <CafePageMenu />
        <CafePageStore />
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Café ー 洗濯の待ち時間も、おいしいコーヒーで豊かな時間に。"
    description="バルコの一部店舗では、カフェを併設しています。洗濯を待っている時間も快適にゆったりとした時間をお過ごしください。こだわりのコーヒーや軽食は、店内だけではなく、テイクアウトでもお楽しみいただけます。"
  />
)
export default CafePage

export const query = graphql`
  query ($limit: Int = 3, $skip: Int = 0) {
    allStrapiInformation(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { uid: { eq: "cafe" } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          id
          slug
          title
          publishedAt
          thumbnail_image
        }
      }
    }
  }
`
