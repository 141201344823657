import React from "react"
import { Link } from "gatsby"

export const CafePageNews = ({ data }) => {
  return (
    <section className="cafePageNews">
      <h2 className="cafePageNews__title e">News</h2>
      <div className="cafePageNews__contents">
        {data.allStrapiInformation.edges.map(({ node }) => (
          <div className="cafePageNews__item" key={node.id}>
            <Link
              className="cafePageNews__itemContents"
              to={`/news/${node.slug}`}
            >
              <div className="cafePageNews__imgBox">
                <img
                  width="100%"
                  src={node.thumbnail_image}
                  className="cafePageNews__newsImg"
                  alt=""
                  loading="lazy"
                />
              </div>
              <h3 className="cafePageNews__postTitle">{node.title}</h3>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}
