import React from "react"
import { Link } from "gatsby"

export const CafePageStore = () => {
  
  return (
    <section className="cafePageStore">
      <div className="cafePageStore__storeListContents">
        <h2 className="cafePageStore__title e">Store List</h2>
        <div className="cafePageStore__lists">
          {/* 店舗 */}
          <div className="cafePageStore__storeBox">
            <div
              className="cafePageStore__imageBox"
              style={{
                backgroundImage:
                  "url(https://balukoweb.blob.core.windows.net/images/cafe/service/service-uehara.jpg)",
              }}
            >
              代々木上原
            </div>
            <div className="cafePageStore__storeInfo">
              <h3 className="cafePageStore__storeName">
                Baluko Café
                <br />
                代々木上原
              </h3>
              <p className="cafePageStore__addr">東京都渋谷区上原3-29-2</p>
              <p className="cafePageStore__logo">
                <img
                  className="cafePageStore__cafeLogo"
                  alt="バルコカフェ"
                  src="https://balukoweb.blob.core.windows.net/images/cafe/logo/baluko-cafe-logo.png"
                  width={100}
                />
              </p>
            </div>
            <div className="cafePageStore__text mh">
              <p className="cafePageStore__lead">
                Baluko Laundry
                Placeフラグシップ店に併設した店内は日当たりが良く、明るい空間が特徴。店外にはテラス席があり、ドッグリードもご用意。コーヒーやドーナツのテイクアウトもご利用いただけます。
              </p>
            </div>
            <p className="cafePageStore__linkText">
              <Link to="/baluko-uehara" className="cafePageStore__linkText">
                店舗詳細
              </Link>
            </p>
          </div>
          {/* 店舗 */}
          <div className="cafePageStore__storeBox">
            <div
              className="cafePageStore__imageBox"
              style={{
                backgroundImage:
                  "url(https://balukoweb.blob.core.windows.net/images/cafe/service/donuts-cafe.jpg)",
              }}
            >
              イオンタウン金沢示野
            </div>
            <div className="cafePageStore__storeInfo">
              <h3 className="cafePageStore__storeName">
                どうなつ日和
                <br />
                イオンタウン金沢示野
              </h3>
              <p className="cafePageStore__addr">石川県金沢市戸板西1-55</p>
              <p className="cafePageStore__logo">
                <img
                  className="pc"
                  alt="バルコカフェ"
                  src="https://balukoweb.blob.core.windows.net/images/cafe/service/donuts-biyori-store-logo.png"
                  width={66}
                />
                <img
                  className="sp"
                  alt="バルコカフェ"
                  src="https://balukoweb.blob.core.windows.net/images/cafe/service/donuts-biyori-store-logo.png"
                  width={50}
                />
              </p>
            </div>
            <div className="cafePageStore__text mh">
              <p className="cafePageStore__lead">
                北海道で大人気のドーナツブランド「ふわもち邸」が監修。道産小麦などこだわりの原料を使用し、店内にある工房でひとつひとつ手作りしています。ふわふわ軽い食感と甘さ控えめの優しい味のドーナツ。金沢にゆかりのある食材を使った限定メニューもご用意しています。
              </p>
              <p className="cafePageStore__lead">
                <a href="https://donut-biyori.jp" className="cafePageStore__linkText">
                  どうなつ日和ウェブサイト：donut-biyori.jp
                </a>
              </p>
            </div>
            <p className="cafePageStore__linkText">
              <Link to="/baluko-aeontownkanazawashimeno" className="cafePageStore__linkText">
                店舗詳細
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
