import React from "react"

export const CafePageMenu = () => {
  return (
    <section className="cafePageMenu">
      <h2 className="cafePageMenu__title e">Food &amp; Drink</h2>
      <p className="cafePageMenu__lead fw-b">
          道産小麦や天然酵母など、こだわりの原料を使用した<br />
          もちもちふわふわのドーナツやベーグル、<br />
          香り高いコロンビアのスペシャリティコーヒーなどをご用意しています。
      </p>
      <div className="cafePageMenu__imageWrap">
        <img
          className="cafePageMenu__image pc"
          src="https://balukoweb.blob.core.windows.net/images/cafe/food/img01.jpg"
          alt=""
          loading="lazy"
        />
        <img
          className="cafePageMenu__image sp"
          src="https://balukoweb.blob.core.windows.net/images/cafe/food/sp-img01.jpg"
          alt=""
          loading="lazy"
        />
      </div>
    </section>
  )
}
